<template>
  <div class="manage">
    <!-- 面包屑 -->
    <!-- tab栏 -->
    <div class="tabs">
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        stretch
        type="card"
      >
        <el-tab-pane label="挂牌采购" name="first">
          <div class="box-block">
            <div class="block">
              <div
                v-for="(item, index) in this.guapaicaigou"
                :key="index"
                class="divBlock"
                :class="{ divBlockActive: index == 0 }"
                @click="guapaicaigouClick(item)"
              >
                <div class="divBlock_header">{{ item.enterpriseName }}</div>
                <div class="huowu">
                  <div class="dun">
                    <div class="goods-name" :title="item.goodsName">{{ item.goodsName }}</div>
                    <div class="nums">
                      {{
                        item.ifConsultRealityCount == 0
                          ? item.stockCount + "吨"
                          : "以实际结算数量为准"
                      }}
                    </div>
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice != '0.00'
                    "
                  >
                    ￥{{ item.goodsCategoryList[0].goodsPrice }}
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice == '0.00'
                    "
                  >
                    ￥面议
                  </div>
                  <div class="money" v-if="item.goodsCategoryList.length > 1">
                    详情查看
                  </div>
                </div>
                <div class="address">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.originPlace"
                    placement="top-start"
                  >
                    <i class="hy-icon-shouhuodizhi"></i>
                  </el-tooltip>
                  {{ item.originPlace }}
                </div>
                <div
                  class="display"
                  v-if="
                    (item.stockCount == 0 || item.goodsStatus == 1) &&
                    item.ifConsultRealityCount == 0
                  "
                >
                  <div class="text">已过期</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total,  prev, pager, next, jumper"
                :total="get_dto.total"
                :current-page="get_dto.page"
                :page-size="get_dto.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="挂牌销售" name="second">
          <div class="box-block">
            <div class="block">
              <div
                class="divBlock"
                v-for="(item, index) in this.guapaixiaoshou"
                :key="index"
                @click="guapaixiaoshouClick(item)"
              >
                <div class="divBlock_header">{{ item.enterpriseName }}</div>
                <div class="huowu">
                  <div class="dun">
                    <div class="goods-name" :title="item.goodsName">{{ item.goodsName }}</div>
                    <div class="nums">
                      {{
                        item.ifConsultRealityCount == 0
                          ? item.stockCount + "吨"
                          : "以实际结算数量为准"
                      }}
                    </div>
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice != '0.00'
                    "
                  >
                    ￥{{ item.goodsCategoryList[0].goodsPrice }}
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice == '0.00'
                    "
                  >
                    ￥面议
                  </div>
                  <div class="money" v-if="item.goodsCategoryList.length > 1">
                    详情查看
                  </div>
                </div>
                <div class="address">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.originPlace"
                    placement="top-start"
                  >
                    <i class="hy-icon-shouhuodizhi"></i>
                  </el-tooltip>
                  {{ item.originPlace }}
                </div>
                <div
                  class="display"
                  v-if="
                    (item.stockCount == 0 || item.goodsStatus == 1) &&
                    item.ifConsultRealityCount == 0
                  "
                >
                  <div class="text">已过期</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total,  prev, pager, next, jumper"
                :total="get_dto.total"
                :current-page="get_dto.page"
                :page-size="get_dto.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="竞价采购" name="third">
          <div class="box-block">
            <div class="block">
              <div
                class="divBlock"
                v-for="(item, index) in this.jingjiacaigou"
                :key="index"
                @click="jingjiacaigouClick(item)"
              >
                <div class="divBlock_header">{{ item.enterpriseName }}</div>
                <div class="huowu">
                  <div class="dun">
                    <div class="goods-name" :title="item.goodsName">{{ item.goodsName }}</div>
                    <div class="nums">{{ item.stockCount }}吨</div>
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice != '0.00'
                    "
                  >
                    ￥{{ item.goodsCategoryList[0].goodsPrice }}
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice == '0.00'
                    "
                  >
                    ￥面议
                  </div>
                  <div class="money" v-if="item.goodsCategoryList.length > 1">
                    详情查看
                  </div>
                </div>
                <div class="address">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.originPlace"
                    placement="top-start"
                  >
                    <i class="hy-icon-shouhuodizhi"></i>
                  </el-tooltip>
                  {{ item.originPlace }}
                </div>
                <div
                  class="display"
                  v-if="item.goodsStatus == 4 || item.goodsStatus == 2"
                >
                  <div class="text">已过期</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total,  prev, pager, next, jumper"
                :total="get_dto.total"
                :current-page="get_dto.page"
                :page-size="get_dto.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="竞价销售" name="fourth">
          <div class="box-block">
            <div class="block">
              <div
                class="divBlock"
                v-for="(item, index) in this.jingjiaxiaoshou"
                :key="index"
                @click="jingjiaxiaoshouClick(item)"
              >
                <div class="divBlock_header">{{ item.enterpriseName }}</div>
                <div class="huowu">
                  <div class="dun">
                    <div class="goods-name" :title="item.goodsName">{{ item.goodsName }}</div>
                    <div class="nums">{{ item.stockCount }}吨</div>
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice != '0.00'
                    "
                  >
                    ￥{{ item.goodsCategoryList[0].goodsPrice }}
                  </div>
                  <div
                    class="money"
                    v-if="
                      item.goodsCategoryList.length === 1 &&
                      item.goodsCategoryList[0].goodsPrice == '0.00'
                    "
                  >
                    ￥面议
                  </div>
                  <div class="money" v-if="item.goodsCategoryList.length > 1">
                    详情查看
                  </div>
                </div>
                <div class="address">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.originPlace"
                    placement="top-start"
                  >
                    <i class="hy-icon-shouhuodizhi"></i>
                  </el-tooltip>
                  {{ item.originPlace }}
                </div>
                <div
                  class="display"
                  v-if="item.goodsStatus == 4 || item.goodsStatus == 2"
                >
                  <div class="text">已过期</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total, prev, pager, next, jumper"
                :total="get_dto.total"
                :current-page="get_dto.page"
                :page-size="get_dto.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// import { getHeaderPage } from "@/api/userCenter";
import { getHeaderPage } from "@/api/userCenters.js";
export default {
  props: {},
  data() {
    return {
      isDisplayed: true,
      activeName: "first",
      // 发起请求的参数
      get_dto: {
        followFlag: "true",
        page: 1,
        size: 10,
        total: 0,
        selectOrderType: 0,
        selectTradeType: 0,
      },
      // 挂牌采购
      guapaicaigou: [],
      // 挂牌销售
      guapaixiaoshou: [],
      // 竞价采购
      jingjiacaigou: [],
      // 竞价销售
      jingjiaxiaoshou: [],
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/guanzhu", title: "我的收藏" },
      ],
    };
  },
  computed: {},
  created() {
    // 获取列表数据
    this.getPages();
  },
  methods: {
    // 获取数据
    getPages(newPage) {
      // 挂牌采购
      this.guapaicaigou = [];
      // 挂牌销售
      this.guapaixiaoshou = [];
      // 竞价采购
      this.jingjiacaigou = [];
      // 竞价销售
      this.jingjiaxiaoshou = [];
      if (this.activeName == "first") {
        // 挂牌采购
        this.get_dto.selectOrderType = 2;
        this.get_dto.selectTradeType = 1;
      } else if (this.activeName == "second") {
        // 挂牌销售
        this.get_dto.selectOrderType = 1;
        this.get_dto.selectTradeType = 1;
      } else if (this.activeName == "third") {
        // 竞价采购
        this.get_dto.selectOrderType = 2;
        this.get_dto.selectTradeType = 2;
      } else {
        // 竞价销售
        this.get_dto.selectOrderType = 1;
        this.get_dto.selectTradeType = 2;
      }
      let config = {
        followFlag: "true",
        page: newPage ? newPage : 1,
        size: 9,
        // total: 0,
        selectOrderType: this.get_dto.selectOrderType,
        selectTradeType: this.get_dto.selectTradeType,
      };
      // 发起请求
      getHeaderPage(config).then((res) => {
        if (this.activeName == "first") {
          // 挂牌采购
          this.guapaicaigou = res.data.records;
          this.get_dto.total = res.data.total;
          // this.get_dto.page = res.data.current;
          this.get_dto.size = res.data.size;
        } else if (this.activeName == "second") {
          // 挂牌销售
          this.guapaixiaoshou = res.data.records;
          console.log(this.guapaixiaoshou);
          this.get_dto.total = res.data.total;
          // this.get_dto.page = res.data.current;
          this.get_dto.size = res.data.size;
        } else if (this.activeName == "third") {
          // 竞价采购
          this.jingjiacaigou = res.data.records;
          console.log(this.jingjiacaigou);
          this.get_dto.total = res.data.total;
          // this.get_dto.page = res.data.current;
          this.get_dto.size = res.data.size;
        } else {
          // 竞价销售
          let list = res.data.records;
          list.forEach((item) => {
            console.log(Date.parse(item.biddingEtartTime));
          });
          this.jingjiaxiaoshou = res.data.records;
          this.guapaicaigou = res.data.records;
          this.get_dto.total = res.data.total;
          // this.get_dto.page = res.data.current;
          this.get_dto.size = res.data.size;
        }
      });
    },
    guapaicaigouClick(item) {
      if (!this.beforhandle("personalCenter:goodsCollect:detail")) return;
      if (
        (item.stockCount == 0 || item.goodsStatus == 1) &&
        item.ifConsultRealityCount == 0
      ) {
        return;
      }
      var timestamp = Date.parse(new Date());
      if (
        (item.stockCount == 0 && item.ifConsultRealityCount == 0) ||
        Date.parse(item.biddingEtartTime) < timestamp
      ) {
        return;
      } else {
        this.$router.push({
          path: "goodsDetail",
          query: {
            goodsId: item.goodsId,
            selectTradeType: 1,
            selectOrderType: 2,
            title: "挂牌采购",
          },
        });
      }
    },
    guapaixiaoshouClick(item) {
      // debugger
      if (!this.beforhandle("personalCenter:goodsCollect:detail")) return;
      if (
        (item.stockCount == 0 || item.goodsStatus == 1) &&
        item.ifConsultRealityCount == 0
      ) {
        return;
      }
      var timestamp = Date.parse(new Date());
      if (
        (item.stockCount == 0 && item.ifConsultRealityCount == 0) ||
        Date.parse(item.biddingEtartTime) < timestamp
      ) {
        return;
      } else {
        this.$router.push({
          path: "goodsDetail",
          query: {
            goodsId: item.goodsId,
            selectTradeType: 1,
            selectOrderType: 1,
            title: "挂牌销售",
          },
        });
      }
    },
    jingjiacaigouClick(item) {
      if (!this.beforhandle("personalCenter:goodsCollect:detail")) return;
      if (item.goodsStatus == 4 || item.goodsStatus == 2) {
        return;
      }
      var timestamp = Date.parse(new Date());
      if (
        item.stockCount == 0 ||
        Date.parse(item.biddingEtartTime) < timestamp
      ) {
        return;
      } else {
        this.$router.push({
          path: "goodsDetail",
          query: {
            goodsId: item.goodsId,
            selectTradeType: 2,
            selectOrderType: 2,
            title: "竞价采购",
          },
        });
      }
    },
    jingjiaxiaoshouClick(item) {
      if (!this.beforhandle("personalCenter:goodsCollect:detail")) return;
      if (item.goodsStatus == 4 || item.goodsStatus == 2) {
        return;
      }
      var timestamp = Date.parse(new Date());
      if (
        item.stockCount == 0 ||
        Date.parse(item.biddingEtartTime) < timestamp
      ) {
        return;
      } else {
        this.$router.push({
          path: "goodsDetail",
          query: {
            goodsId: item.goodsId,
            selectTradeType: 2,
            selectOrderType: 1,
            title: "竞价销售",
          },
        });
      }
    },
    // 改变页码
    changePage(newPage) {
      console.log(newPage);
      this.get_dto.page = newPage;
      this.getPages(newPage);
    },
    // 每页显示信息条数
    handleSizeChange(val) {
      this.get_dto.size = val;
      if (this.get_dto.page === 1) {
        this.getTransactionList();
      }
    },
    handleCurrentChange(val) {
      this.get_dto.page = val;
      this.getTransactionList();
    },
    // Tab栏目切换
    handleClick({ label: lable }) {
      this.getPages();
      this.get_dto.page = 1;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-breadcrumb__item {
  height: 39px;
  line-height: 39px;
  // padding-top: 10px;
}
.is-top {
  margin-bottom: 0;
}
::v-deep .el-tabs__content {
  height: 640px;
  margin-bottom: 15px;
  // background-color: #4cb2fc;s
}

::v-deep .el-tabs__item {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  // color: #0294ff;
  // color: #666666;
  line-height: 60px;
  height: 60px;
}

::v-deep .el-tab-pane {
  min-width: 250px;
}

.popContainer {
  position: relative;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  z-index: 999;
  width: 300px;
  height: 250px;
  background: rgba(0, 0, 0, 0.3);
  .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: white;
  }
}
::v-deep .el-tabs__header {
  margin-bottom: 0;
}
.tabs {
  min-height: 500px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-bottom: 18px;
  .box-block {
    min-height: 600px;
  }
  .block {
    display: flex;
    flex-wrap: wrap;

    .divBlock {
      padding: 10px;
      position: relative;
      width: 275px;
      height: 165px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-left: 35px;
      margin-top: 25px;
      color: #333333;
      .divBlock_header {
        width: 200px;
        overflow: hidden;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 46px;
        height: 46px;
        white-space: nowrap;
        text-overflow: ellipsis;
        // margin-left: 17px;
      }
      .huowu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding-top: 20px;
        margin-bottom: 15px;
        // padding-right: 18px;
        border-top: 1px solid #dddddd;
        .dun {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 14px;
          font-family: PingFang SC;
          // margin-left: 18px;
          .nums {
            // margin-top: 13px;
          }
          .goods-name {
            max-width: 160px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .money {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fe7b35;
          line-height: 42px;
          margin-top: 20px;
        }
      }
      .address {
        font-size: 12px;
        font-family: PingFang SC;
        // margin-left: 16px;
        padding-top: 32px;
        color: #999999;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 255px;
        i {
          margin-right: 8px;
        }
      }
      .display {
        text-align: center;
        line-height: 100%;
        // display: none;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 998;
        border-radius: 10px;
        .text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
          color: white;
        }
      }
    }
    .divBlock:hover {
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      .divBlock_header {
        color: #ffffff;
      }
      .dun {
        color: #ffffff;
      }
      .address {
        color: #ffffff;
      }
      .money {
        color: #fff100 !important;
      }
    }
  }
}

.pagination {
  padding: 0 100px;
  float: right;
}
.is-active {
  border-bottom: 0;
}
.manage {
  margin-top: 20px;
}
</style>
